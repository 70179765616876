// @flow
import { graphql } from 'gatsby';
import React from 'react';

import Feed from '../components/Feed';
import Layout from '../components/Layout';
import Page from '../components/Page';
import Sidebar from '../components/Sidebar';

type Props = {|
  +data: Object,
|};

const ArchiveTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = data.site.siteMetadata;
  const { edges } = data.allMarkdownRemark;

  return (
      <Layout
        title={`Blog Archive - ${siteTitle}`}
        description={`An archive of all articles. ${siteSubtitle}`}
      >
        <Sidebar />
        <Page>
          <Feed edges={edges} isShortened={true}/>
        </Page>
      </Layout>
  );
};

export const query = graphql`
{
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          template: { eq: "post" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
            fields {
              categorySlug
              slug
            }
            frontmatter {
              date
   
              category
              title
            }
          }
      }
    }
  }
`;

export default ArchiveTemplate;
